$(document).foundation();

$(document).ready(function() {
    $('[data-validate-form]').each(function(i, val) {
        $(val).validate({
            errorPlacement: function() { }
        });
    });

    setTimeout(function() {
        $('.section__top').addClass('animate');

        if($('.coordinates > span').length) {
            setTimeout(() => {
                $('.coordinates > span').show();

                $('[data-number]').each(function() {
                    $(this).animateNumber({
                        number: $(this).attr('data-number')
                    }, 3500);
                });
            },500);
        }
    },500);

});

$('#nav button.hamburger').on('click', function() {
    $('.menu').toggleClass('menu__open');
    $(this).toggleClass('is-active');
});

if($('#homeSwiper').length) {
    var swiper = new Swiper('#homeSwiper', {
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: {
            delay: 5000
        },
        speed: 1000,
        loop: true
    });
}

function Utils() {}

Utils.prototype = {
    constructor: Utils,
    isElementInView: function (element, fullyInView) {
        var pageTop = $(window).scrollTop();
        var pageBottom = pageTop + $(window).height();
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).height();

        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    }
};

var Utils = new Utils();

$(window).on('scroll', function() {
    $('[data-is-visible]').each(function() {
        if(!$(this).hasClass('is-visible') && Utils.isElementInView($(this), true)) {
            $(this).addClass('is-visible');
        }
    });
})
